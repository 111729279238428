import { Train } from "@jozys/db-api-wrapper/dist/src/types/connection";
import {
  DelayType,
  IJourneyDetailed,
  IJourneyPreview,
  ITrainJourney,
  ITrainJourneyDetailed,
  TrainType,
} from "@jozys/db-delay-types";
import { AxiosError, AxiosResponse } from "axios";
import moment from "moment-timezone";

export default async function createJourney(
  data: IJourneyDetailed,
  token: string
): Promise<{ message: string | number; variant: string }> {
  // get full information of trains
  if (data.trains) {
    const trainJourneys: ITrainJourney[] = [];
    try {
      for (const train of data.trains) {
        const { data }: AxiosResponse<Partial<Train>> = await axios.post(
          //@ts-ignore
          `/db/train/`,
          { id: train?.id },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        const trainJourney: ITrainJourneyDetailed = {
          arrival: moment(train.arrival).toDate(),
          departure: moment(train.departure).toDate(),
          delay: {
            delay: train.delay?.delay ?? 0,
            reason: train.delay?.reason as DelayType,
          },
          operator: train.operator,
          displayName: train.displayName,
          trainStart: {
            id: data.fromId ?? train.trainStart?.id,
            name: data.from ?? train.trainStart?.name,
          },
          trainEnd: {
            id: data.toId ?? train.trainEnd?.id,
            name: data.to ?? train.trainEnd?.name,
          },
          startStation: {
            id: train.startStation.id,
            name: train.startStation.name,
          },
          endStation: {
            id: train.endStation.id,
            name: train.endStation.name,
          },
          id: data.id as string,
          line: isNaN(train.line) ? parseInt(data.id ?? "-1") : train.line,
          type: train.type.toUpperCase() as TrainType,
          duration: Math.abs(
            moment(train.departure).diff(train.arrival, "minutes")
          ),
        };
        trainJourneys.push(trainJourney);
      }

      // now sending the full journey to the server
      const postData = {
        ...data,
        endDate: moment(data.endDate).toDate(),
        startDate: moment(data.startDate).toDate(),
        trains: trainJourneys,
        passengers: data.passengers,
        otherPeople: data.others,
        price: 0,
        startStationId: data.startStation,
        endStationId: data.endStation,
        delay: data.delay ?? 0,
      };
      const response: AxiosResponse<IJourneyPreview> = await axios.post(
        "/journey",
        postData,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return {
        message: response.data.id ?? -1,
        variant: response.data.id ? "success" : "error",
      };
    } catch (e) {
      if (e instanceof AxiosError) {
        return e.response?.data;
      }
      return { message: "An unknown error occured", variant: "error" };
    }
  }
  return { message: "An unknown error occured", variant: "error" };
}
